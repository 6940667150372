import { GlobalStyles as MUIGlobalStyles } from '@mui/material';

const GlobalStyles = () => (
  <MUIGlobalStyles styles={{
    body: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
      padding: 0,
      width: `100vw`, /* Take up 100% of the viewport width */
      boxSizing: 'border-box' /* Include padding and border in the width */
    },
  }} />
);

export default GlobalStyles;
