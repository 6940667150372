import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const MainPage: React.FC = () => {
  const [inputText, setInputText] = useState("");
  const navigate = useNavigate();

  const handleSend = () => {
    navigate(`/results/${inputText}`);
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar style={{ width: "100vw" }}>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Menu open={false} keepMounted></Menu>
          <h4>Debug Tool</h4>
        </Toolbar>
      </AppBar>
      <Container>
        <Box mt={5}>
          <TextField
            label="Enter transaction ID"
            variant="outlined"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
            sx={{ mt: 2 }}
          >
            Lookup
          </Button>
        </Box>
      </Container>
    </>
  );
};

export default MainPage;
