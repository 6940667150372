import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  TextField,
  styled,
  Button,
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Snackbar,
  SnackbarCloseReason,
  SnackbarContent,
  CircularProgress,
  Fab,
  Alert as MuiAlert
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

import "./ResultsPage.css"; // Import the CSS file
import { useNavigate } from "react-router-dom";

interface Entry {
  Name: string;
  Ts: string;
  Text: string;
  EntryType: string;
  SourceName: string;
  Id: string;
InternalEntryId: string;
}

interface Alert {
    EntryId : string;
    Message : string;
    Severity : string;
}

interface ApiResponse {
  entries: Entry[];
}

const ResultsPage: React.FC = () => {
  const history = useNavigate();
  const { inputText } = useParams<{ inputText: string }>();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchInText, setSearchInText] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState<Set<string>>(new Set());
  const [hiddenEntries, setHiddenEntries] = useState<Set<string>>(new Set());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [txTs, setTxTs] = useState("");
  const [txContactId, setTxContactId] = useState("");
  const [entries, setEntries] = useState<Entry[]>([]);
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const handleBackClick = () => {
    history("/");
  };

  const handleFilterTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterText(event.target.value);
  };

  const handleSearchInTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchInText(event.target.checked);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getAlertIcon = (severity: string) => {
    switch (severity) {
      case 'warning':
        return <WarningIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'info':
        return <InfoIcon />;
      default:
        return null;
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const showHideEntries = (keyword: string) => {
    setHiddenEntries((prev) => {
      const newHiddenEntries = new Set(prev);
      entries.forEach((entry) => {
        const headerText = `[${formatDateTime(
          entry.Ts
        ).toLowerCase()}] [${entry.EntryType.toLowerCase()}] 
      [${entry.SourceName.toLowerCase()}] [${entry.Id.toLowerCase()}] [${entry.Name.toLowerCase()}]`;

        if (
          hiddenEntries.has(headerText) &&
          headerText.includes(keyword.toLowerCase())
        ) {
          newHiddenEntries.delete(headerText);
        } else if (headerText.includes(keyword.toLowerCase())) {
          newHiddenEntries.add(headerText);
        }
      });
      return newHiddenEntries;
    });
    handleMenuClose();
  };

  const filterEntries = (entries: Entry[]) => {
    return entries.filter((entry) => {
      const headerText = `[${formatDateTime(
        entry.Ts
      ).toLowerCase()}] [${entry.EntryType.toLowerCase()}] 
      [${entry.SourceName.toLowerCase()}] [${entry.Id.toLowerCase()}] [${entry.Name.toLowerCase()}]`;
      const collapsibleText = entry.Text.toLowerCase();
      const textMatches = searchInText
        ? headerText.includes(filterText.toLowerCase()) ||
          collapsibleText.includes(filterText.toLowerCase())
        : headerText.includes(filterText.toLowerCase());

      return textMatches && !hiddenEntries.has(headerText);
    });
  };

  const getAccordionBackgroundColor = (headerText: string): string => {
    if (headerText.includes("[BigQuery]")) {
      return "#ffffee";
    } else if (headerText.includes("[MySQL]")) {
      if (headerText.includes("[progress action")) {
        return "#B9CDAA";
      }
      return "#A5B996";
    } else if (headerText.includes("[Logs]")) {
      return "#ffffff";
    } else if (headerText.includes("[Other errors]")) {
      return "#ffebe6";
    }

    return "#ffffff"; // Default white
  };

  const CustomAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: "#ffffff", // Default white background
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://debug.slace.com/transactions/${inputText}`,
          {
            headers: {
              "Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setEntries(response.data.entries);
        setAlerts(response.data.alerts);
        setTxTs(response.data.tx_timestamp);
        setTxContactId(response.data.tx_contact_id);
      } catch (error: any) {
        if (error.response) {
          if (error.response.status === 401) {
            console.log("Unauthorized");
            history("/login");
          } 

          if (error.response.status === 500) {
            console.log(error.response.data);
            console.log(error.response.data.error);
            setSnackbarMessage(error.response.data.error);
            setSnackbarOpen(true);
          } else {
            console.log("Error:", error.response.data);
          }
        }  else if (error.request) {
          console.log("No response received:", error.request);
        } else {
          console.log("Error", error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [inputText]);

  const fetchNextTx = async () => {
    try {
      const response = await axios.get(
        `https://debug.slace.com/contacts/${txContactId}/next-tx?ts=${txTs}`
      );

      history("/results/" + response.data.tx_id);
    } catch (error: any) {
      setLoading(false)
      if (error.response) {
        if (error.response.status === 500 || error.response.status === 400) {
          console.log(error.response.data);
          console.log(error.response.data.error);
          setSnackbarMessage(error.response.data.error);
          setSnackbarOpen(true);
          
        } else {
          console.log("Error:", error.response.data);
        }
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  }

  const fetchPreviousTx = async () => {
    try {
      const response = await axios.get(
        `https://debug.slace.com/contacts/${txContactId}/previous-tx?ts=${txTs}`
      );

      history("/results/" + response.data.tx_id);
    } catch (error: any) {
      setLoading(false)
      if (error.response) {
        if (error.response.status === 500 || error.response.status === 400) {
          console.log(error.response.data);
          console.log(error.response.data.error);
          setSnackbarMessage(error.response.data.error);
          setSnackbarOpen(true);
        } else {
          console.log("Error:", error.response.data);
        }
      } else if (error.request) {
        console.log("No response received:", error.request);
      } else {
        console.log("Error", error.message);
      }
    }
  }

  const formatDateTime = (ts: string) => {
    const date = new Date(ts);
    return date.toLocaleString("pl-PL");
  };

  function formatAndParseJSON(input: string): string {
    // Parse the initial JSON string
    const parsed = JSON.parse(input);

    // Function to recursively parse nested JSON strings
    const reparseNestedJSON = (obj: any): any => {
      for (const key in obj) {
        if (typeof obj[key] === "string") {
          try {
            const nested = JSON.parse(obj[key]);
            if (typeof nested === "object" && nested !== null) {
              obj[key] = reparseNestedJSON(nested);
            }
          } catch (e) {
            // Ignore, not a nested JSON string
          }
        }
      }
      return obj;
    };

    // Reparse nested JSON strings in the parsed object
    const reparsedObject = reparseNestedJSON(parsed);

    // Convert the object back to a formatted JSON string
    const formattedJSON = JSON.stringify(reparsedObject, null, 2);

    return formattedJSON;
  }

  const renderTextContent = (text: string) => {
    try {
      const json = JSON.parse(text);
      return (
        <pre className="pre-wrap">
          {formatAndParseJSON(JSON.stringify(json, null, 2))}
        </pre>
      );
    } catch {
      return <Typography>{text}</Typography>;
    }
  };

  const handleAutoCollapse = () => {
    setExpandedPanels(new Set());
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleExport = () => {
    const filename = `tx-${inputText}.log`;
    const fileContent = filterEntries(entries).map(entry => `[${formatDateTime(entry.Ts)}] [${
        entry.EntryType
      }] [${entry.SourceName}] [${entry.Id}] [${entry.Name}]\n\n${entry.Text}`).join('\n\n');
    const blob = new Blob([fileContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanels((prev) => {
        const newExpandedPanels = new Set(prev);
        if (isExpanded) {
          newExpandedPanels.add(panel);
        } else {
          newExpandedPanels.delete(panel);
        }
        return newExpandedPanels;
      });
    };
  return (
    <>
      <AppBar position="static">
        <Toolbar style={{ width: "100vw" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleAutoCollapse}>Auto-Collapse</MenuItem>
            <MenuItem
              onClick={() => {
                console.log(hiddenEntries);
                showHideEntries("[MySQL]");
              }}
            >
              Show/Hide [MySQL]
            </MenuItem>
            <MenuItem onClick={() => showHideEntries("[BigQuery]")}>
              Show/Hide [BigQuery]
            </MenuItem>
            <MenuItem onClick={() => showHideEntries("[HTTP]")}>
              Show/Hide [HTTP]
            </MenuItem>
            <MenuItem onClick={() => showHideEntries("[Logs]")}>
              Show/Hide [Logs]
            </MenuItem>
            <MenuItem onClick={() => showHideEntries("[Other errors]")}>
              Show/Hide [Other errors]
            </MenuItem>
            <MenuItem onClick={handleExport}>Export as file</MenuItem>
          </Menu>
          <h4>Debug Tool</h4>
        </Toolbar>
      </AppBar>
      {/* <Box
        display="flex"
        justifyContent="space-between"
        mb={4}
        mt={4}
        ml={4}
        mr={4}
      >
        {txContactId && txTs && (
          <Button

            onClick={() => {
              setTxContactId("");
              setTxTs("");
              setLoading(true);
              setEntries([]);
              setAlerts([]);
              fetchPreviousTx();
            }}
            variant="contained"
            color="primary"
          >
            Previous Transaction
          </Button>
        )}

        {txContactId && txTs && (
          <Button
            onClick={() => {
              setTxContactId("");
              setTxTs("");
              setLoading(true);
              setEntries([]);
              setAlerts([]);
              fetchNextTx();
            }}
            variant="contained"
            color="primary"
          >
            Next Transaction
          </Button>
        )}
      </Box> */}
      <Container>
        <Box mt={2} mb={2}>
          <Typography color="lightgray" variant="h5">
            Transaction: {inputText}
          </Typography>
        </Box>

        <Box mt={2} mb={2}>
          <TextField
            label="Search timeline"
            variant="outlined"
            value={filterText}
            onChange={handleFilterTextChange}
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={searchInText}
                onChange={handleSearchInTextChange}
                name="searchInText"
                color="primary"
              />
            }
            label="Include payload"
          />
        </Box>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <CircularProgress />
            <Typography variant="h6" color="textSecondary">
              Collecting data...
            </Typography>
          </Box>
        ) : (
          <>
            {filterEntries(entries).length > 0 ? (
              filterEntries(entries).map((entry, index) => {
                const headerText = `[${formatDateTime(entry.Ts)}] [${
                  entry.EntryType
                }] [${entry.SourceName}] [${entry.Id}] [${entry.Name}]`;
                const backgroundColor = getAccordionBackgroundColor(headerText);
                const alert = alerts ? alerts.find(alert => alert.EntryId === entry.InternalEntryId) : null;
                return (
                  <CustomAccordion
                    key={index}
                    expanded={expandedPanels.has(`panel${index}`)}
                    onChange={handleChange(`panel${index}`)}
                    style={{ backgroundColor }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                          <Box display="flex" alignItems="center" width="100%">
                      <Typography>{headerText}</Typography>
                      {alert && (
                        <MuiAlert
                          icon={getAlertIcon(alert.Severity)}
                          severity={alert.Severity as 'info' | 'warning' | 'error'}
                          style={{ marginLeft: 'auto', marginRight: '20px'}}
                        >
                          {alert.Message}
                        </MuiAlert>
                      )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {renderTextContent(entry.Text)}
                    </AccordionDetails>
                  </CustomAccordion>
                );
              })
            ) : (
              <Typography>
                <p style={{ color: "red" }}>
                  Failed to collect data ({snackbarMessage})
                </p>
              </Typography>
            )}
          </>
        )}

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <SnackbarContent
            style={{
              backgroundColor: "red",
              color: "white",
            }}
            message={snackbarMessage}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={handleSnackbarClose}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
        </Snackbar>
      </Container>
      <Fab
        aria-label="back"
        onClick={handleBackClick}
        style={{
          position: "fixed",
          bottom: "50px",
          left: "50px",
          backgroundColor: "#1976d2",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ArrowBackIcon />
      </Fab>
    </>
  );
};

export default ResultsPage;
