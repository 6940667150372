import axios from 'axios';

export const setAuthToken = (token: string | null) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const getToken = () => localStorage.getItem('token');

export const getTokenExp = () => localStorage.getItem('expiresAt');