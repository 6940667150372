import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainPage from './components/MainPage';
import ResultsPage from './components/ResultsPage';
import { AuthProvider } from './useAuth';
import LoginPage from './components/LoginPage';
import GlobalStyles from './GlobalStyles';
import PrivateRoute from './components/PrivateRoute';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <GlobalStyles />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<PrivateRoute element={<MainPage />} />} />
          <Route path="/results/:inputText" element={<PrivateRoute element={<ResultsPage />} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;